@if (toasterTitle || toasterMessage) {
	<div class="c-toaster" [ngClass]="[toasterType, hasBackgroundColor ? 'has-background-color' : '']">
		<div class="c-toaster__content">
			@if (toasterIcon || toasterSubtitle) {
				<div class="c-toaster__content__subtitle u-margin-bottom-sm">
					@if (toasterIcon) {
						<fa-icon
							class="c-toaster__icon"
							[icon]="toasterIcon.icon"
							[iconType]="toasterIcon.type"
							[ngClass]="{ 'has-custom-icon': customToasterIcon }"
						/>
					}
					@if (toasterSubtitle) {
						<span class="c-vloket-text--light" cypressTag="Toaster.Subtitle">
							{{ toasterSubtitle }}
						</span>
					}
				</div>
			}
			@if (toasterTitle) {
				<p class="c-toaster__content__title u-margin-bottom-sm" cypressTag="Toaster.Title">
					{{ toasterTitle }}
				</p>
			}
			@if (toasterMessage) {
				<p class="c-toaster__content__message" cypressTag="Toaster.Message" [innerHTML]="toasterMessage"></p>
			}
			@if (toasterLinks?.length > 0) {
				<div class="c-toaster__content__links">
					@for (toasterLink of toasterLinks; track toasterLink) {
						<cjm-link
							class="u-margin-top"
							cypressTag="Toaster.Link"
							classes="c-toaster__content__link"
							[ngClass]="{
								'c-toaster__content__link--primair': toasterLink.actionType === actionTypes.PRIMARY,
								'c-toaster__content__link--secondair': toasterLink.actionType === actionTypes.SECONDARY
							}"
							[type]="toasterLink.type"
							[to]="toasterLink.url"
							[title]="toasterLink.title"
							[icon]="toasterLink.icon"
							[keepInTab]="toasterLink.keepInTab"
							[noDecoration]="true"
						>
							{{ toasterLink.text }}
						</cjm-link>
					}
				</div>
			}
		</div>
		@if (isClosable) {
			<cjm-button
				cypressTag="Toaster.Close"
				[classes]="buttonClasses.Link"
				[title]="i18nKeys.Common.Close | translate"
				(handleClick)="closeButtonClicked.emit()"
			>
				<fa-icon icon="fa-solid fa-xmark" />
			</cjm-button>
		}
	</div>
}
