const g = e => !e || typeof e != "string" ? "" : e.match(/[A-Z][a-z]+/g).map((r, t) => t === 0 ? r : r.toLowerCase()).join(" ").trim(),
  j = e => typeof e != "string" ? e : e.replaceAll(/[\s\u00A0]/gi, " "),
  b = (e, r, t) => {
    if (!e) return -1;
    const c = t && e[t] ? e[t] : e;
    return typeof c != "string" ? -1 : c.localeCompare(r);
  },
  A = (e, r) => {
    if (!e || Object.values(e).length === 0) return;
    const t = Object.values(e).indexOf(r);
    return Object.keys(e)[t];
  };
function f(e) {
  return e && e.__esModule && Object.prototype.hasOwnProperty.call(e, "default") ? e.default : e;
}
var u = e => {
  if (Object.prototype.toString.call(e) !== "[object Object]") return !1;
  const r = Object.getPrototypeOf(e);
  return r === null || r === Object.prototype;
};
const l = u,
  a = (e, r, t) => {
    let c = e;
    return r.cleanArrays === !0 && (c = e.map(n => o(n, r, t + 1)).filter(Boolean)), t === 0 || r.preserveArrays === !0 || c.length > 0 ? c : null;
  },
  o = (e, r, t) => {
    const c = {};
    if (!l(e)) return Array.isArray(e) ? a(e, r, t) : e;
    for (const n of Object.keys(e)) if (!(e[n] === null || e[n] === void 0)) if (Array.isArray(e[n])) {
      const s = a(e[n], r, t + 1);
      s !== null && (c[n] = s);
    } else if (l(e[n])) {
      const s = o(e[n], r, t + 1);
      s !== null && Object.keys(s).length > 0 && (c[n] = s);
    } else e[n] !== "" && (c[n] = e[n]);
    return t > 0 && Object.keys(c).length === 0 ? null : c;
  },
  y = (e, r) => {
    const t = {
      preserveArrays: !0,
      cleanArrays: !0,
      ...r
    };
    return o(e, t, 0);
  };
var i = y;
const O = /* @__PURE__ */f(i),
  d = (e, ...r) => r.reduce((t, c) => {
    const [n, s] = c;
    return s == null || typeof s == "object" && Object.keys(O(s)).length === 0 ? t : {
      ...t,
      [n]: s
    };
  },
  // Iben: Cast the start value to any to prevent issues with typing
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  e);
export { g as camelCaseToSentence, A as getKeyByValue, d as merge, j as replaceHtmlWhitespace, b as stringComparator };