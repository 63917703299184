import { MatSnackBarConfig } from '@angular/material/snack-bar';

import { AlertType, LinkType } from '@cjm/shared/ui/common';

import { generateSnackbarConfig } from '../generate-snackbar-config/generate-snackbar-config';

/**
 * generateSnackbarErrorConfig
 *
 * The generateSnackbarErrorConfig is a wrapper around generateSnackbarConfig
 * that generates a snackbar config with a default error type and title when
 * receiving an error object.
 *
 * @param error{any}
 * @param config{{type?: AlertType; title?: string;}}
 * @returns MatSnackBarConfig
 */
export const generateSnackbarErrorConfig = (
	error: any,
	config: {
		type?: AlertType;
		title?: string;
	} = {
		type: AlertType.Error,
		title: ''
	}
): MatSnackBarConfig => {
	return generateSnackbarConfig({
		message: error?.errorMessage,
		title: config.title ? config.title : '',
		type: config.type ? config.type : AlertType.Error,
		...(Array.isArray(error?.acties) && error.acties.length > 0
			? {
					links: error.acties.map((actie) => ({
						type: LinkType.external,
						keepInTab: true,
						title: actie.actietekst,
						text: actie.actietekst,
						url: actie.actielink,
						actionType: actie.actietype
					}))
				}
			: {})
	});
};
