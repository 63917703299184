import { catchAndCallThrough } from '@studiohyperdrive/rxjs-utils';
import { catchError } from 'rxjs/operators';

import { AlertType } from '@cjm/shared/ui/common';

import { SnackBarService } from '../../../data';
import { SnackBarComponent } from '../../components';
import { generateSnackbarErrorConfig } from '../../helpers';

/**
 * catchWithSnackBar
 *
 * The catchWithSnackBar will use the provided SnackBarService instance and the catchAndCallThrough operator
 * to catch an error and show it as a toast message.
 *
 * @param snackBarService
 * @param config
 * @param config.type is set to AlertType.Error by default
 * @param config.title is set to '' by default
 * @param handle whether to throw, continue or complete the observable stream (see catchAndCallThrough operator)
 */
export function catchWithSnackBar(
	snackBarService: SnackBarService,
	config: {
		type?: AlertType;
		title?: string;
	} = {
		type: AlertType.Error,
		title: ''
	},
	// Denis: mimic the handle of the catchAndCallThrough
	handle: 'throw' | 'continue' | 'complete' = 'continue'
): ReturnType<typeof catchError> {
	return catchAndCallThrough((res) => {
		const error = res?.error;

		if (!error) {
			return;
		}

		return snackBarService.openFromComponent(SnackBarComponent, generateSnackbarErrorConfig(error, config));
	}, handle);
}
