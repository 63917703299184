import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

import { SnackBarService } from '../../../data';
import { ToasterComponent } from '../toaster/toaster.component';
import { Toaster } from '../toaster/toaster.types';

@Component({
	templateUrl: './snack-bar.component.html',
	standalone: true,
	imports: [ToasterComponent]
})
export class SnackBarComponent {
	constructor(
		@Inject(MAT_SNACK_BAR_DATA) public readonly data: Toaster,
		private readonly snackBarService: SnackBarService
	) {}

	public closeMessage(id: string, persistClosure: 'session' | 'local'): void {
		this.snackBarService.dismiss(id, persistClosure);
	}
}
